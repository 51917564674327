import { useState } from "react";
import Image from "next/image";
import { Button } from "@/components/ui/Button";

export default function Gallery() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleThumbnailClick = (index: any) => {
    setActiveIndex(index);
  };

  const handlePrev = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? galleryImages.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === galleryImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const galleryImages = [
    {
      src: "/images/staff/hero-with-head-clinicians_1413x941.jpg",
      alt: "",
      width: 1413,
      height: 941,
    },
    {
      src: "/images/clinic/Varicose-Veins-Treatment.webp",
      alt: "Varicose Veins Treatment",
      width: 2664,
      height: 1776,
    },
    {
      src: "/images/clinic/dr-daye-clinical-admin.jpg",
      alt: "Dr. Daye Administrative Follow-up",
      width: 1413,
      height: 941,
    },
    {
      src: "/images/clinic/cypress-cardiovascular-institute-facility_632x372.jpg",
      alt: "Cypress Cardiovascular Institute Facility",
      width: 632,
      height: 372,
    },
    {
      src: "/images/clinic/performing-checkups.jpg",
      alt: "Performaning Checkups",
      width: 1413,
      height: 931,
    },
    {
      src: "/images/clinic/front-office-sign.jpg",
      alt: "Front Office",
      width: 1413,
      height: 794,
    },
    {
      src: "/images/clinic/demonstration.jpg",
      alt: "demonstration",
      width: 1200,
      height: 799,
    },
  ];

  return (
    <div className="container mx-auto my-10 md:my-20">
      <div className="px-4">
        <h2 className="leading-tight font-heading tracking-tighter text-[2.5rem] sm:text-[2.81rem] md:text-5xl lg:text-[3.5rem] xl:text-6xl capitalize mb-8">
          Cypress Cardiovascular Gallery
        </h2>
        <p className="text-left mb-8 text-lg">
          Experience our state-of-the-art clinic and compassionate patient care
        </p>
      </div>

      <div className="flex flex-col items-center px-4">
        <div className="relative w-full h-0 pb-[62.5%] md:w-full md:h-[500px]">
          <Image
            src={galleryImages[activeIndex].src}
            alt={galleryImages[activeIndex].alt}
            layout="fill"
            objectFit="cover"
            className="rounded cursor-pointer"
            onClick={toggleModal}
          />
        </div>
        <div className="inline-flex container mx-auto items-center justify-center">
        <Button variant="uiarrow" onClick={handlePrev}>
              <ChevronLeftIcon className="w-6 h-6" />
            </Button>
          <div className="relative items max-w-[800px] mt-4 overflow-x-auto">
            {/* <Button variant="uiarrow" onClick={handlePrev}>
              <ChevronLeftIcon className="w-6 h-6" />
            </Button> */}
            <div className="flex space-x-2">
              {galleryImages.map((image, index) => (
                <div
                  key={index}
                  className={`w-[100px] h-[75px] flex-shrink-0 cursor-pointer border-2 ${
                    activeIndex === index
                      ? "border-blue-500"
                      : "border-gray-300"
                  }`}
                  onClick={() => handleThumbnailClick(index)}
                >
                  <Image
                    src={image.src}
                    alt={image.alt}
                    width={100}
                    height={75}
                    objectFit="cover"
                    className="rounded"
                  />
                </div>
              ))}
            </div>
          </div>
            <Button variant="uiarrow" onClick={handleNext}>
              <ChevronRightIcon className="w-6 h-6" />
            </Button>
        </div>
        </div>

      {isModalOpen && (
        <div
          className="fixed inset-0 bg-blue-800 bg-opacity-75 flex justify-center items-center z-50"
          onClick={toggleModal}
        >
          <div className="relative w-full max-w-3xl">
            <Image
              src={galleryImages[activeIndex].src}
              alt={galleryImages[activeIndex].alt}
              width={galleryImages[activeIndex].width}
              height={galleryImages[activeIndex].height}
              objectFit="contain"
              className="rounded"
            />
            <div className="absolute bottom-4 left-0 right-0 text-center text-white bg-blue-800 bg-opacity-75 py-2">
              {galleryImages[activeIndex].alt}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function ChevronLeftIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m15 18-6-6 6-6" />
    </svg>
  );
}

function ChevronRightIcon(props: any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m9 18 6-6-6-6" />
    </svg>
  );
}