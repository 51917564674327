// constants/teamMembers.tsx
export const teamMembers = [
  {
    imgSrc: '/images/staff/dr-daye-round.png',
    role: 'President, Interventional Cardiologist',
    name: 'Jad Daye, M.D., RPVI',
    description: "Dr. Jad Daye is a board-certified interventional cardiologist with expertise in coronary, structural and peripheral interventions. He currently serves as the President of Cypress Cardiovascular Institute, Chief of Staff Elect at HCA North Cypress Hospital, and Associate Professor at the University of Houston School of Medicine.",
  },
  {
    imgSrc: '/images/staff/dr-hust-round.png',
    role: 'Interventional Cardiologist',
    name: 'Michael Hust, M.D.',
    description: "Dr. Michael Hust is an interventional cardiologist at Cypress Cardiovascular Institute. He is board-certified in internal medicine, cardiovascular disease, and interventional cardiology. Dr. Hust is dedicated to providing comprehensive cardiovascular care to patients in Cypress and surrounding areas.",
  },
  {
    imgSrc: '/images/staff/marisa-nurse-practitioner-round.png',
    role: 'Nurse Practitioner',
    name: 'Marisa Cueva, NP-C',
    description: "Marisa Cueva is Dr. Jad Daye's and Dr. Michael Hust's Cardiac Nurse Practitioner at Cypress Cardiovascular Institute.",
  },
];