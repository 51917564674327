// /components/TeamSlider.tsx

import { teamMembers as defaultTeamMembers } from 'constants/teamMembers';
import { useState } from 'react';

interface TeamMember {
  imgSrc: string;
  role: string;
  name: string;
  description: string;
}

interface TeamSliderProps {
  teamMembers?: TeamMember[];
}

const TeamSlider: React.FC<TeamSliderProps> = ({ teamMembers = defaultTeamMembers }) => {
  const [activeSlide, setActiveSlide] = useState(1);
  const slideCount = teamMembers.length;

  const handlePrevSlide = () => {
    setActiveSlide(activeSlide > 1 ? activeSlide - 1 : slideCount);
  };

  const handleNextSlide = () => {
    setActiveSlide(activeSlide < slideCount ? activeSlide + 1 : 1);
  };

  return (
    <section className="py-12 md:py-20 overflow-hidden">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap -mx-4 items-center mb-20">
          <div className="w-full lg:w-8/12 xl:w-1/2 px-4 mb-8 lg:mb-0">
            <h2 className="leading-tight font-heading tracking-tighter text-[2.5rem] sm:text-[2.81rem] md:text-5xl lg:text-[3.5rem] xl:text-6xl ">Meet the Team</h2>
          </div>
          <div className="w-full lg:w-4/12 xl:w-1/2 px-4">
            <div className="flex items-center justify-end">
              <button
                className="inline-flex h-16 sm:h-18 w-16 sm:w-18 mr-8 items-center justify-center text-black hover:text-white hover:bg-blue-800 border border-black rounded-full transition duration-200"
                onClick={handlePrevSlide}
              >
                <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10.7051 7.12817L4.15732 13.6759L10.7051 20.2237"
                    stroke="currentColor"
                    strokeWidth="1.61806"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M22.4941 13.6759H4.33949"
                    stroke="currentColor"
                    strokeWidth="1.61806"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </button>
              <button
                className="inline-flex h-16 sm:h-18 w-16 sm:w-18 items-center justify-center text-black hover:text-white hover:bg-blue-800 border border-black rounded-full transition duration-200"
                onClick={handleNextSlide}
              >
                <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M16.2949 7.12817L22.8427 13.6759L16.2949 20.2237"
                    stroke="currentColor"
                    strokeWidth="1.61806"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M4.50586 13.6759H22.6605"
                    stroke="currentColor"
                    strokeWidth="1.61806"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div
          style={{ transform: `translateX(-${(activeSlide - 1) * 100.5}%)` }}
          className="transition-transform duration-500 ease-in-out"
        >
          <div className="flex items-start">
            {teamMembers.map((member, index) => (
              <div key={index} className="flex-shrink-0 mr-6 xl:mr-12 w-full max-w-md">
                <img className="block mb-8" src={member.imgSrc} alt={member.name} />
                <div className="max-w-sm">
                  <span className="text-sm text-50-600">{member.role}</span>
                  <h3 className="leading-tight tracking-tighter text-black text-[2.1rem] sm:text-[2.3rem] md:text-[2.45rem] lg:text-[2.75rem] xl:text-5xlmt-1 mb-6">{member.name}</h3>
                  <p className="text-lg text-slate-900">{member.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamSlider;